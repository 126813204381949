import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import "./detailPageFooterActions.scss";

type DetailPageFooterActionsProps = ComponentPropsWithoutRef<"div"> & {
    /**
     * @default "end"
     */
    placement?: DetailPageFooterPlacement;
};

export function DetailPageFooterActions(props: DetailPageFooterActionsProps) {
    const { placement = "end", ...restProps } = props;

    return (
        <div
            {...restProps}
            className={clsx(
                "detail-page-footer-actions",
                {
                    "detail-page-footer-actions--start": placement === "start",
                    "detail-page-footer-actions--end": placement === "end",
                },
                restProps.className
            )}
        />
    );
}

type DetailPageFooterPlacement = "start" | "end";
