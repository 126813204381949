import { IonFooter } from "@ionic/react";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import "./detailPageFooterRoot.scss";

type DetailPageFooterProps = ComponentPropsWithoutRef<"div">;

export const DetailPageFooterRoot = (props: DetailPageFooterProps) => {
    return (
        <IonFooter>
            <div className="detail-page-footer-root">
                <div {...props} className={clsx("detail-page-footer-root__content", props.className)} />
            </div>
        </IonFooter>
    );
};
